.setup-organization {
  margin: 0 auto;
  background: url('../../images/login/login_bg.jpg');
  background-position: center;
  padding: 30px 12px;
  min-height: 100vh;
  h3 {
    text-align: center;
    margin-bottom: 1em;
    font-weight: 600;
  }
}