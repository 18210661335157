.steps {
  nav.p-steps {
    font-family: $font-cairo;
    ul {
      li::before { display: none; }
      li { 
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        a {
          width: 100%;
          align-items: center;
          background: transparent;
          &:hover {
            text-decoration: none;
          }
          span.p-steps-number {
            color: #424242;
            border: 2px solid #424242;
            background: #E0E0E0;
            min-width: 2.5rem;
            height: 2.5rem;
            line-height: 2rem;
            font-size: 16px;
            z-index: 1;
            border-radius: 50%;
            font-weight: 700;
          }
          span.p-steps-title {
            color: #424242;
            font-weight: 700;
            font-size: 16px;
          }
          &.p-menuitem-link-active {
            span.p-steps-number {
              border-color: #60d7a9;
              background-color: #FFF;
              color: #60d7a9;
            }
            span.p-steps-title {
              color: #60d7a9;
            }
          }
        }
        &:not(:last-child)::after, &:not(:first-child)::before {
          content: "";
          width: 50%;
          // height: 2px;
          display: block;
          background-color: #424242;
          position: absolute;
          border-top: 2px solid #424242;
        }
        &:not(:first-child)::before {
          right: 0;
          top: 50%;
        }
        &:not(:last-child)::after {
          right: 0;
          top: 28%;
        }
        &:not(.p-disabled) {
          &::before, &::after {
            border-color: #8B8B8D;
          }
          a {
            span.p-steps-number {
              border-color: #60d7a9;
              background-color: #FFF;
              color: #60d7a9;
            }
            span.p-steps-title {
              color: #60d7a9;
            }
          }
        }
      }
    }
  }
}

.step-data {
  background-color: #fff;
  padding: 25px;
  border-radius: 15px;
  margin-top: 2em;
}