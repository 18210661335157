/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import "../node_modules/font-awesome/scss/font-awesome.scss";

@import "../node_modules/angular-calendar/css/angular-calendar.css";

body {
  overflow-x: hidden;
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  box-sizing: border-box;
  touch-action: manipulation;
  background: #fff;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

.swal2-actions {
  gap: 1em;
}

.transparent-button {
  background-color: transparent;
  border: none;
}

.filter-header {
  p {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
  }
}

.gap-10 {
  gap: 10px;
}

.custom-file-upload {
  width: 100%;

  // label.mainLabel {
  //   color: #424242;
  //   font-weight: 700;
  //   font-size: 16px;
  // }
  .upload-box {
    width: 100%;
    display: flex;

    .upload {
      padding: 10px 14px;
      width: 20%;
      border-radius: 8px 0px 0px 8px;
      background: #F5F7FD;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 2px 5px 0px rgba(103, 110, 118, 0.08);
      cursor: pointer;

      label {
        margin: 0;
        cursor: pointer;
      }
    }

    .file-info {
      display: flex;
      padding: 10px 14px;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      border-radius: 0px 8px 8px 0px;
      background: #FFF;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 2px 5px 0px rgba(103, 110, 118, 0.08);
      width: 100%;

      span#file-chosen {
        color: #424242;
      }
    }
  }
}

.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  padding: 30px;

  p {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.50);
  }
}

.apexcharts-legend-series {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.e-diagram {
  display: block;
  direction: ltr;
  // overflow: scroll !important;
}

.p-organizationchart {
  overflow-x: auto;
  direction: ltr;
}

.p-organizationchart .p-organizationchart-line-top {
  border-top: 2px solid #6BA5D7;
  border-color: #6BA5D7;
}

.p-organizationchart .p-organizationchart-line-left {
  border-right: 2px solid #6BA5D7;
  border-color: #6BA5D7;
}

.p-organizationchart-table>tbody>tr>td {
  padding: 0 3px;
}

.building {
  background-color: #6BA5D7;
}

.floor {
  background-color: #ff3364;
}

.room {
  background-color: #495057;
}

.organization {
  background-color: #16c7f9;
}

.inputGroup {
  padding: 0.375rem 2.25rem;
  background-color: #e9ecef;
}

.text-purple {
  color: #7366ff !important;
}

.divider {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 1.5rem 0;

  .divider-text {
    position: relative;
    display: inline-block;
    font-size: 0.9375rem;
    padding: 0 1rem;
    color: #7366ff;
    font-weight: 600;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 1000%;
      border-top: 1px solid #ebe9f1;
      border-color: #7366ff !important;
    }

    &:before {
      left: 100%;
    }

    &:after {
      right: 100%;
    }
  }
}

.toast-container {
  position: fixed !important;
}

.form-control,
.form-select {
  &.errorInput {
    border-color: #dc3545 !important;
  }

  &.isValid {
    border-color: #198754 !important;
  }
}

.radio_animated {
  &.errorInput {
    &:after {
      border-color: #dc3545 !important;
    }
  }

  &.isValid {
    &:after {
      border-color: #198754 !important;
    }
  }
}

.rounded-modal {
  .modal-content {
    border-radius: 20px;
  }
}

table {

  td,
  th {
    width: 1%;
    white-space: nowrap;
    text-align: center !important;
  }

  td.actions {

    // min-width: 8rem;
    .actions-box {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
    }

    .img {
      width: 20px;
      justify-content: center;
    }

    button.p-element {
      padding: 0;
      height: auto;
      outline: none !important;
      box-shadow: none;
    }

    .p-button.p-button-icon-only {
      // width: auto;
    }
  }
}

timepicker table td {
  text-align: unset !important;
  width: auto;
}

textarea {
  resize: none;
}

.change-view {
  h3.title {
    font-size: 1.285rem;
    margin: 0;
  }

  &~.content {
    p {
      color: rgba(82, 82, 108, 0.8);
    }
  }
}

.p-component {
  font-family: 'cairo';
}

.priceList .p-datatable .p-datatable-tbody>tr>td {
  border-bottom: 1px solid #dee2e6;
  padding: 0 0.75rem;
  font-size: 14px;
}

.timepicker-overlay {
  direction: ltr;
}

.user-image {
  position: relative;
  // height: 0;
  margin-bottom: 3em;

  .avatar {
    display: flex;
    justify-content: center;

    img {
      width: 100px;
      height: 100px;
      max-width: 155px;
      max-height: 155px;
      border-radius: 50%;
      border: 4px solid #ccc;
      padding: 7px;
      cursor: pointer;
    }
  }

  .icon-wrapper {
    position: absolute;
    bottom: -10px;
    left: 51%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: $white;
    cursor: pointer;
    overflow: hidden;
    margin: 0 auto;
    font-size: 17px;
    box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1);
  }
}

.p-element {
  .p-paginator {
    flex-wrap: nowrap;
    direction: ltr;

    .p-inputwrapper {
      width: auto;
    }
  }

  &.p-inputwrapper {
    width: 100%;

    .p-dropdown {
      width: 100%;
    }

    span {
      &:not(.p-inputgroup-addon) {
        width: 100%;
      }

      &.p-inputgroup-addon {
        // border: none;
        border-top: 1px solid #d1d5db;
        border-left: 1px solid #d1d5db;
        border-bottom: 1px solid #d1d5db;
      }

      input {
        width: 100%;
      }

      .p-datepicker {
        direction: ltr;
      }
    }
  }

  .p-inputgroup {
    input {
      border: 1px solid #d1d5db;
      width: 100%;
      outline: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  &.p-button {
    border-radius: 6px;
  }

  .p-dropdown {
    border: 1px solid #E0E4EA;
    outline: none;
    box-shadow: none;
  }

  &.p-dropdown-item {
    padding: 0.5rem;
  }

  &.errorInput>.p-dropdown {
    border-color: #dc3545;
  }

  &.isValid>.p-dropdown {
    border-color: #198754;
  }
}


.template-filter {
  ul.p-dropdown-items {
    padding: 0;
  }

  .p-element {
    &.p-dropdown-item {
      transition: all 0.3s;

      &:hover {
        background: rgba(115, 103, 240, 0.12);
        color: #7367f0;
      }
    }
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.errors {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.changeOrder {
  background: transparent;
  border: 1px solid #dee2e6;
  padding: 7px;
  border-radius: 6px;
  // margin: 0 10px;
}

timepicker .btn.btn-default {
  border: 1px solid #efefef;
  background-color: #fff;
  border-radius: 0.375rem;
  font-size: 14px;
  color: #898989;
  padding: 12px 10px;
  box-shadow: none;
}

.box.statBox {
  padding: 20px 10px;
  box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
  position: relative;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 17px;
  min-height: 100px;

  .info {
    display: flex;
    align-items: center;
    gap: 10px;

    .icon {
      border: 1px solid #fff;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .details {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

  }

  &.box1 {
    background-image: linear-gradient(135deg, #ABDCFF 10%, #0396FF 100%);
  }

  &.box2 {
    background-image: linear-gradient(135deg, #2AFADF 10%, #4C83FF 100%);
  }

  &.box3 {
    background-image: linear-gradient(135deg, #FFD3A5 10%, #FD6585 100%);
  }

  &.box4 {
    background-image: linear-gradient(135deg, #EE9AE5 10%, #5961F9 100%);
  }
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
  font-family: 'cairo' !important;
}

.p-dropdown-label {
  font-family: 'Cairo';
  font-size: 14px;

  div.flex {
    div {
      font-family: 'Cairo';
      font-size: 14px;
    }
  }
}

p-multiselect {
  display: block;
  width: 100%;

  .p-multiselect.p-component {
    width: 100%;
  }
}

ul.p-dropdown-items {
  padding: 0;

  li.p-dropdown-item {
    div.flex {
      div {
        font-size: 14px;
      }
    }
  }
}


.file-upload-container {
  display: flex;
  justify-content: center;
  border: 2px dashed #b3d9b3;
  padding: 10px;
  align-items: center;
  background-color: #f7f7f7;
  position: relative;

  .file-upload-area {
    width: 400px;
    height: 150px;
    border-radius: 8px;
    background-color: #f0fdf4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;

    .upload-icon {
      margin-bottom: 10px;

      svg {
        width: 36px;
        height: 36px;
        color: #7a7a7a;
      }
    }

    .file-upload-text {
      font-size: 16px;
      color: #7a7a7a;

      .file-link {
        color: #26a69a;
        text-decoration: none;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &:hover {
      background-color: #e6f5e6;
    }
  }
}

.file-upload-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;

}

.min-vh-75 {
  min-height: 75vh !important;
}

.custome_accordion {
  .p-accordion-header-link {
    color: black;
    background: #ffffff;
    justify-content: space-between;
    flex-direction: row-reverse;
    border: 0;

    .p-icon-wrapper {
      padding: .3rem;
      border: 1px solid black;
      border-radius: 50%;
    }

    .p-accordion-header-text {
      font-size: 1.2rem;
    }
  }

  .p-accordion-tab {
    margin-bottom: 1rem;
  }

  .p-accordion-content {
    border: 0;
  }
}