/**=====================
 custom CSS Start
==========================**/
// you can add your custom css here

.flex {
  display: flex !important;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.ac-sb {
  align-content: space-between;
}

.ai-c {
  align-items: center;
}

.ai-fe {
  align-items: flex-end;
}

.ai-fs {
  align-items: flex-start;
}

.flex-item {
  flex-basis: 0;
  flex-grow: 1;
}

.jc-c {
  justify-content: center;
}

.jc-sb {
  justify-content: space-between;
}

.center-flex {
display: flex;
justify-content: center;
align-items: center;
}

.space-between-flex {
display: flex;
justify-content: space-between;
align-items: center;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-width-height {
  width: 100%;
  height: 100%;
}

.auto-width {
  width: auto;
}

.auto-height {
  height: auto;
}

.position-relative {
  position: relative;
}

.transparent-button {
  background: transparent;
  border: none
}